body {
  margin: 0;
  font-family: "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
root {
  font-family: "Gilroy", sans-serif;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}